<template>
  <b-modal v-model="showModal" title="Schedule New Train" centered hide-footer>
    <form @submit.prevent="createTrain()">
      <div class="row train-form">

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Name
              <span class="text-danger">*</span>
            </label>
            <input id="name" v-model="train.name" type="text" class="form-control"
                   placeholder="Name"
                   :class="{ 'is-invalid': submitted && v$.train.name.$error }"
            />
            <div v-if="submitted && v$.train.name.$error" class="invalid-feedback">
                                <span v-if="v$.train.name.required.$message">
                                    {{ v$.train.name.required.$message }}
                                </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Group
              <small class="text-muted">(optional)</small>
            </label>
            <input id="group" type="text" class="form-control visually-hidden"
                   placeholder="Name"
            />
            <Multiselect v-model="train.group_id" v-bind="groupIdSelect"/>

          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Type
              <span class="text-danger">*</span>
            </label>
            <select id="type" v-model="train.train_type" class="form-control"
                    :class="{ 'is-invalid': submitted && v$.train.train_type.$error }">
              <option v-for="option in types" :key="option" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <div v-if="submitted && v$.train.train_type.$error" class="invalid-feedback">
                        <span v-if="v$.train.train_type.required.$message">
                            {{ v$.train.train_type.required.$message }}
                        </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Status
              <span class="text-danger">*</span>
            </label>
            <select id="type" v-model="train.status" class="form-control"
                    :class="{ 'is-invalid': submitted && v$.train.status.$error }">
              <option v-for="option in statuses" :key="option" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <div v-if="submitted && v$.train.status.$error" class="invalid-feedback">
                        <span v-if="v$.train.status.required.$message">
                            {{ v$.train.status.required.$message }}
                        </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Color
              <span class="text-danger">*</span>
            </label>
            <select id="type" v-model="train.color" class="form-control"
                    :class="{
                                    'is-invalid': submitted && v$.train.color.$error,
                                    'text-primary': train.color === 'bg-primary',
                                    'text-success': train.color === 'bg-success',
                                    'text-danger': train.color === 'bg-danger',
                                    'text-secondary': train.color === 'bg-secondary',
                                    'text-info': train.color === 'bg-info',
                                    'text-dark': train.color === 'bg-dark',
                                    'text-warning': train.color === 'bg-warning',
                                }"
            >
              <option v-for="option in categories" :key="option"
                      :class="'text-' + option.value.replace('bg-', '')" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <div v-if="submitted && v$.train.color.$error" class="invalid-feedback">
                                    <span v-if="v$.train.color.required.$message">
                                        {{ v$.train.color.required.$message }}
                                    </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Route
              <span class="text-danger">*</span>
            </label>
            <input id="route" v-model="train.route" type="text" class="form-control"
                   placeholder="Route"
                   :class="{ 'is-invalid': submitted && v$.train.route.$error }"
            />
            <div v-if="submitted && v$.train.route.$error" class="invalid-feedback">
                        <span v-if="v$.train.route.required.$message">
                            {{ v$.train.route.required.$message }}
                        </span>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="mb-3">
            <label>
              ETD ~ ETA
              <span class="text-danger">*</span>
            </label>
            <div class="input-group">
              <div class="w-100"
                   :class="{ 'border border-danger rounded-2': submitted && v$.train.date.$error }">
                <flat-pickr placeholder="Select date"
                            :config="timeConfig"
                            v-model="train.date"
                            class="form-control flatpickr-input w-100"
                >
                </flat-pickr>
              </div>
              <div v-if="submitted && v$.train.date.$error" class="invalid-feedback">
                                    <span v-if="v$.train.date.required.$message">
                                        {{ v$.train.date.required.$message }}
                                    </span>
              </div>
            </div>
          </div>
        </div>

        <SelectStations ref="selectStations" @onSelect="onStationSelected" :ratio="[6,6,6,6]"/>
        <small v-if="submitted && (!train.departure || !train.destination)" class="mb-3 text-danger">
          Station is a required field
        </small>

        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">
              Description
              <span class="text-danger">*</span>
            </label>
            <textarea class="form-control d-block" v-model="train.description"
                      placeholder="Enter a description" rows="3" spellcheck="false"
                      :class="{ 'is-invalid': submitted && v$.train.description.$error }">
                            </textarea>
            <div v-if="submitted && v$.train.description.$error" class="invalid-feedback">
                <span v-if="v$.train.description.required.$message">{{
                    v$.train.description.required.$message
                  }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-end pt-3">
        <b-button :disabled="submitting" @click="this.showModal = false" variant="light">Close</b-button>
        <b-button :disabled="submitting" :type="submitting ? 'button' : 'submit'" variant="success" class="ms-1">
          {{ submitting ? 'Scheduling...' : 'Schedule train' }}
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import SelectStations from "@/components/custom/SelectStations.vue"
import {categories, types, statuses} from "../utils";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Swal from "sweetalert2";

import moment from "moment";
import axios from "axios";

import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default {
  emits: ["trainCreated"],
  name: "TrainCreate",
  components: {
    SelectStations,
    flatPickr,
    Multiselect
  },
  validations: {
    train: {
      name: {
        required: helpers.withMessage("Name is required", required),
      },
      train_type: {
        required: helpers.withMessage("Type is required", required),
      },
      route: {
        required: helpers.withMessage("Route is required", required),
      },
      color: {
        required: helpers.withMessage("Color is required", required),
      },
      status: {
        required: helpers.withMessage("Status is required", required),
      },
      date: {
        required: helpers.withMessage("Date is required", required),
      },
      description: {
        required: helpers.withMessage("Description is required", required),
      },
      departure: {
        required: helpers.withMessage("Departure station is required", required),
      },
      destination: {
        required: helpers.withMessage("Destination station is required", required),
      },
    },
  },
  setup() {
    return {v$: useVuelidate()};
  },
  data() {
    return {
      timeConfig: {
        enableTime: false,
        altInput: true,
        dateFormat: "Z",
        altFormat: "d M, Y",
        mode: "range",
      },

      train: {
        name: "",
        group_id: null,
        train_type: "export",
        route: "TSR",
        color: "bg-primary",
        status: "expecting",
        date: "",
        departure: null,
        destination: null,
        description: "",
      },
      submitted: false,
      submitting: false,
      categories: categories,
      types: types,
      statuses: statuses,

      groupIdSelect: {
        options: [],
        placeholder: "Select group",
      },

      showModal: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    async createTrain() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        let Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await Toast.fire({
          icon: 'error',
          title: 'Please fill in all required fields'
        })
        return false;
      } else {
        let title = this.train.name;
        let group_id = this.train.group_id;
        let train_type = this.train.train_type;
        let route = this.train.route;
        let status = this.train.status;
        let color = this.train.color;
        let date = this.train.date.split(' to ');
        let departure_station = this.train.departure;
        let destination_station = this.train.destination;
        let description = this.train.description;

        const departure_date = moment(date[0]).add(1, 'day').toISOString().slice(0, 10);
        const expected_arrival_date = moment(date[1]).add(1, 'day').toISOString().slice(0, 10);

        try {
          this.submitting = true
          let response = await axios.post('/train/create/', {
            name: title,
            group_id: group_id,
            type: train_type,
            color: color,
            route: route,
            status: status,
            departure_date: departure_date,
            expected_arrival_date: expected_arrival_date,
            departure_station_id: departure_station.value,
            destination_station_id: destination_station.value,
            description: description,
          })
          this.submitted = false
          this.submitting = false
          this.showModal = false
          this.train = {
            name: "",
            group_id: null,
            train_type: "export",
            route: "TSR",
            color: "bg-primary",
            status: "expecting",
            date: "",
            departure: "",
            destination: "",
            description: "",
          }
          this.$refs.selectStations.clear()
          this.$emit('trainCreated', response.data)
        } catch (error) {
          this.submitting = false
          let html_text = ''
          try {
            html_text = Object.entries(error.response.data.extra.fields)
                .filter(([key]) => key !== 'slug')
                .map(([key, value]) => {
                  return `<span class="fw-medium">${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: </span> ${value}`
                }).join('<br>')
          } catch {
            html_text = 'Error while deleting payment'
          }
          await Swal.fire({
            icon: 'error',
            title: 'Error',
            html: html_text
          })
        }
      }
    },

    async getGroupOptions() {
      try {
        let response = await axios.get('/third_party/railway/groups/')
        this.groupIdSelect.options = response.data.groups.group.map((option) => {
          return {
            value: option.group_id,
            label: option.group_name,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    onStationSelected(event) {
      if (event.option === 'departure') {
        this.train.departure = event.value ? event.value : null
      } else if (event.option === 'destination') {
        this.train.destination = event.value ? event.value : null
      } else {
        alert('Station has not been selected')
      }
    },
  },
  mounted() {
    this.getGroupOptions()
  },
}
</script>

<style scoped>

</style>
<script>
import {
  layoutComputed
} from "@/state/helpers";

import routes from "../router/routes";
import store from "../state/store";
import Menu from "@/core/Menu.js"

export default {
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      routes: routes,
      menus: Menu
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
    user() {
      return store.state.user;
    }
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            childCollapse.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                      "active");
              }
            }
          }
        }
      }, 0);
    },

    getRoutePages(pages) {
      return pages
    },
    getMenus() {
      return this.filterRoutes([...this.routes].filter(route =>
          route.path.indexOf(':') === -1 &&
          route.meta.showInMenu !== false
      ), this.user.pages)
    },
    inDropdownChildren(route) {
      return route.children && route.children.filter(p => p.path.indexOf(':') === -1 && p.inDropdown && p.showInMenu !== false)
    },
    filterRoutes(inputRoutes, allowedPages) {
      return inputRoutes.reduce((acc, route) => {
        // If this route should be included directly
        if (allowedPages.includes(route.name)) {
          acc.push(route);
        }

        // If this route has children, process them
        if (route.children && route.children.length) {
          const filteredChildren = this.filterRoutes(route.children, allowedPages);
          if (filteredChildren.length) {
            // If any of the children should be included, clone the route and set its children to the filtered set
            const clonedRoute = {...route, children: filteredChildren};
            acc.push(clonedRoute);
          }
        }
        return acc;
      }, [])
    }
  },
};
</script>

<template>
  <div class="container-fluid px-0">
    <div id="two-column-menu"></div>
    <template v-if="layoutType === 'vertical'">
      <ul class="navbar-nav h-100" id="navbar-nav">

        <li class="menu-title">
          <span data-key="t-orders-menu" class="badge badge-gradient-info rounded-0 w-100 fs-11">
            InterRail Central Asia
          </span>
        </li>

        <template v-for="route in getMenus()" :key="'route_' + route">
          <li v-if="route.meta.sectionTitle" class="menu-title bg-light">
            <span data-key="t-orders-menu">{{ route.meta.sectionTitle }}</span>
          </li>

          <template v-if="route.children && inDropdownChildren(route).length > 0">
            <li class="nav-item">
              <a class="nav-link menu-link"
                 :href="'#sidebar' + route.name"
                 data-bs-toggle="collapse"
                 role="button"
                 aria-expanded="false"
                 :aria-controls="'sidebar' + route.name"
              >
                <i :class="route.meta.icon ? route.meta.icon : `bx bx-star`"></i>
                <span data-key="t-orders">{{ route.meta.title }} </span>
              </a>
              <div class="collapse menu-dropdown" :id="'sidebar' + route.name">
                <ul class="nav nav-sm flex-column">
                  <li v-for="child in route.children
                          .filter(p => p.path.indexOf(':') === -1 && p.inDropdown && p.showInMenu !== false)"
                      :key="'page_child' + child.name"
                      class="nav-item"
                  >
                    <router-link class="nav-link menu-link"
                                 :to="{path: route.path + child.path}">
                      {{ child.nameInMenu || child.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </template>

          <template v-for="page in route.children
                      .filter(p => p.path.indexOf(':') === -1 && p.inDropdown !== true && p.showInMenu !== false)"
                    :key="'route_page_' + page.name"
          >
            <li class="nav-item align-middle">
              <router-link class="nav-link menu-link" :to="{path: route.path + page.path}">
                <i :class="[page.icon ? page.icon : `bx bxs-star`, `${page.name === 'HomePage' ? 'text-success' : ''}`]"></i>

                <div>
                  <span data-key="t-orders">{{ page.nameInMenu || page.name }}</span>
                  <span
                      :id="`${page.path.replace(/\//g, '')}-menu-item`"
                      class="badge rounded-pill bg-success ms-1 align-middle"
                  >
                    </span>
                </div>
              </router-link>
            </li>
          </template>

        </template>

      </ul>
    </template>
  </div>
</template>
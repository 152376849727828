<template>
  <b-modal v-model="showModal" hide-footer title="Create Contract" modal-class="fade"
           class="v-modal-custom"
           centered>
    <form action="" @submit.prevent="create" class="text-dark">
      <div class="mb-3">
        <label for="contractName" class="form-label">Contract Name</label>
        <input v-model="name"
               type="text" class="form-control" id="contractName" placeholder="Contract name">
      </div>
      <div class="mb-3">
        <label for="contractStartDate" class="form-label">Start Date</label>
        <input v-model="start_date" type="date"
               class="form-control" id="contractStartDate" rows="3" placeholder="Start Date">
      </div>
      <div class="mb-3">
        <label for="contractEndDate" class="form-label">Expire Date</label>
        <input v-model="end_date" type="date"
               class="form-control" id="contractEndDate" rows="3" placeholder="Start Date">
      </div>
      <div class="text-end">
        <button v-if="!isFormValid" type="submit" class="btn btn-success" disabled>Create contract</button>
        <button v-if="isFormValid" type="submit" class="btn btn-success">Create contract</button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import {createContract} from "@/api/client/ClientApi.js"
import Swal from "sweetalert2";

export default {
  emits: ['create'],
  name: "contract_create",
  data() {
    return {
      name: '',
      start_date: '',
      end_date: '',
      showModal: false
    }
  },
  computed: {
    isFormValid() {
      return this.name !== '' && this.start_date && this.end_date
    }
  },
  methods: {
    async create() {
      try {
        await createContract({
          company_slug: this.$route.params.slug,
          name: this.name,
          start_date: this.start_date,
          expire_date: this.end_date
        })
        try {
          document.querySelector('#CreateContractModal .btn-close').click()
        } catch {
          console.log('modal not found')
        }
        this.name = ''
        this.start_date = ''
        this.end_date = ''
        this.$emit('create')
        this.showModal = false
        await Swal.fire({
          icon: 'success',
          title: 'Contract created successfully',
          showConfirmButton: false,
          timer: 2000
        }).then(async () => {
          if (this.$route.query.redirect_success) {
            await this.$router.push({
              path: this.$route.query.redirect_success
            })
          }
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    selectedFile(event) {
      this.file = event.target.files[0];
    },
    deleteRecord() {
      this.file = null
      document.querySelector('#actFile').value = ''
    },
    openModal() {
      this.showModal = true
    }
  },
  mounted() {
    if (JSON.parse(this.$route.query.open_contract_create_modal ? this.$route.query.open_contract_create_modal : false) === true) {
      setTimeout(() => {
        this.openModal()
      }, 300)
    }

    this.$router.beforeEach((to, from, next) => {
      this.showModal = false
      next()
    })
  },
}
</script>

<style scoped>

</style>
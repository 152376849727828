<script>
import {defineComponent} from 'vue'
import Table from "@/components/tables/table.vue";

export default defineComponent({
  name: "OrdersList",
  props: {
    company_type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      counterpartyOrdersTable: {
        name: "Counterparty Orders",
        url: `/counterparty/expanses/${this.$route.params.slug}/`,
        searchable: true,
        selectable: true,
        per_page_location: "bottom",
        headers: [
          {
            field: 'order',
            label: 'Order',
            align: 'center'
          },
          {
            field: 'lot_number',
            label: 'Lot Number',
            align: 'center'
          },
          {
            field: 'category',
            label: 'Category',
            align: 'center'
          },
          {
            field: 'total_sum',
            label: 'Total Expense',
            align: 'center',
            searchable: false
          },
          {
            field: 'completed_actual_cost_price',
            label: 'Actual Cost Price',
            align: 'center',
            searchable: false
          }
        ]
      },
      customerOrdersTable: {
        name: "Customer Orders",
        url: `/customer/expanses/${this.$route.params.slug}/`,
        searchable: true,
        selectable: true,
        per_page_location: "bottom",
        headers: [
          {
            field: 'order',
            label: 'Order',
            align: 'center'
          },
          {
            field: 'lot_number',
            label: 'Lot Number',
            align: 'center'
          },
          {
            field: 'departure',
            label: 'Departure',
            align: 'center'
          },
          {
            field: 'destination',
            label: 'Destination',
            align: 'center'
          },
          {
            field: 'quantity',
            label: 'Quantity',
            align: 'center'
          },
          {
            field: 'total_agreed_rate',
            label: 'Total Agreed Rate',
            align: 'center',
            searchable: false
          },
          {
            field: 'invoice_cost',
            label: 'Invoice Price',
            align: 'center',
            searchable: false
          },
          {
            field: 'invoice_status',
            label: 'Invoice Status',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: '', value: ''},
              {label: 'Paid', value: 'paid'},
              {label: 'Unpaid', value: 'unpaid'},
            ]
          },
          {
            field: 'invoice',
            label: 'Invoice',
            align: 'center',
            searchable: false
          },
          {
            field: 'date',
            label: 'Order Date',
            align: 'center'
          },
        ]
      }
    }
  },
  components: {
    Table
  },
  methods: {
    async downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    capitalizeFirstLetter(string) {
      if (!string) return '-'
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed: {
    tableUrl() {
      return `/counterparty/expanses/${this.$route.params.slug}/`
    }
  }
})
</script>

<template>
  <Table v-bind="counterpartyOrdersTable" v-if="company_type === 'counterparty'">
    <template v-slot:order="{row:data}">
          <span v-if="data.order_type === 'container_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_container_detail', params: {id: data.order }}">
            {{ data.order }}
          </router-link>
          </span>
      <span v-if="data.order_type === 'wagon_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_wagon_detail', params: {id: data.order }}">
            {{ data.order }}
          </router-link>
          </span>
      <span v-if="data.order_type === 'wagon_empty_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_empty_wagon_detail', params: {id: data.order }}">
            {{ data.order }}
          </router-link>
          </span>
    </template>
    <template v-slot:lot_number="{row: data}">
      <div v-if="data.lot_number">
        <div>
          <router-link v-if="data.order_type === 'container_order'"
                       :to="{name:'order_container_list', query: {lot_number: data.lot_number}}">
            {{ data.lot_number }}
          </router-link>
          <router-link v-else-if="data.order_type === 'wagon_order'"
                       :to="{name:'order_wagon_list', query: {lot_number: data.lot_number}}">
            {{ data.lot_number }}
          </router-link>
          <router-link v-else-if="data.order_type === 'wagon_empty_order'"
                       :to="{name:'order_empty_wagon_list', query: {lot_number: data.lot_number}}">
            {{ data.lot_number }}
          </router-link>
        </div>
        <span class="d-block text-muted">
              {{
            data.order_type ? data.order_type
                .split('_').map(word => word[0].toUpperCase() + word.slice(1))
                .join(' ') : 'Unknown'
          }}
            </span>
      </div>
      <div v-else></div>
    </template>
    <template v-slot:total_sum="{row: data}">
      <i class="bx bx-dollar"></i>
      {{ data.total_sum }}
    </template>
    <template v-slot:completed_actual_cost_price="{row: data}">
      <i class="bx bx-dollar"></i>{{ data.completed_actual_cost_price || 0 }}
      /
      <i class="bx bx-dollar"></i>{{ data.actual_cost_price || 0 }}
      <div class="d-flex mx-auto align-items-center" style="max-width: 250px">
        <div class="flex-shrink-0 me-3">
          <div class="avatar-xs">
            <div class="avatar-title bg-light rounded-circle text-muted fs-11">
              {{ data.completed_actual_cost_count }}/{{ data.actual_cost_count }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <b-progress animated
                      :value="((data.completed_actual_cost_price || 0) / data.actual_cost_price) * 100"
                      class="custom-progress progress-label"
                      :variant="data.completed_actual_cost_count === data.actual_cost_count ? 'success' : 'primary'">
          </b-progress>
        </div>
      </div>
    </template>
  </Table>

  <Table v-bind="customerOrdersTable" v-else-if="company_type === 'customer'">
    <template v-slot:order="{row:data}">
      <span v-if="data.order.type === 'container_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_container_detail', params: {id: data.order.number }}">
            {{ data.order.number }}
          </router-link>
      </span>
      <span v-if="data.order.type === 'wagon_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_wagon_detail', params: {id: data.order.number }}">
            {{ data.order.number }}
          </router-link>
      </span>
      <span v-if="data.order.type === 'wagon_empty_order'" class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_empty_wagon_detail', params: {id: data.order.number }}">
            {{ data.order.number }}
          </router-link>
      </span>
    </template>
    <template v-slot:invoice="{row:data}">
      <span @click="downloadFile(data.invoice.file)" v-if="data.invoice"
            class="text-decoration-underline link-secondary">
        <i class="bx bxs-download me-1 align-middle"></i>
        {{ data.invoice.id || 'Invoice' }}
      </span>
    </template>

    <template v-slot:invoice_cost="{row:data}">
      <span v-if="data.invoice">
        $ {{
          parseFloat((data.invoice.total_cost || 0)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        }}
      </span>
      <span v-else>0</span>
    </template>

    <template v-slot:invoice_status="{row:data}">
      <span v-if="data.invoice" :class="{
        'text-danger': data.invoice.paid_status === 'unpaid',
        'text-success': data.invoice.paid_status === 'paid'
      }">
        {{ capitalizeFirstLetter(data.invoice.paid_status) }}
      </span>
      <span v-else>-</span>
    </template>

    <template v-slot:total_agreed_rate="{row:data}">
      $ {{
        parseFloat((data.total_agreed_rate || 0)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      }}
    </template>

  </Table>
</template>

<style scoped>

</style>
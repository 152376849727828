let url = '/customer'
import axios from "axios";

function validateResponse(response) {
    if (response.status === 200) {
        return response.data
    } else {
        return null
    }
}

export async function getClients() {
    let response = await axios.get(url + '/companies/')
    return validateResponse(response)
}


export async function getCompanyByName(companyName) {

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.get(url + '/companies/' + companyName + '/')
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }
    return response
}

export async function updateCompany(company) {

    const formData = new FormData();

    formData.append('name', company.name);
    formData.append('slug', company.slug);
    formData.append('email', company.email);
    formData.append('phone', company.phone);
    formData.append('address', company.address);
    formData.append('about', company.about);

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.put(url + '/companies/' + company.slug + '/', formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }
    return response
}

export async function createCompany(company) {

    const req_url = url + '/company_create/';
    const formData = new FormData();

    formData.append('name', company.name);
    formData.append('email', company.email);
    formData.append('phone', company.phone);
    formData.append('address', company.address);
    formData.append('about', company.about);
    formData.append('is_customer', company.is_customer ? company.is_customer : false);
    formData.append('is_counterparty', company.is_counterparty ? company.is_counterparty : false);
    formData.append('counterparty_name', company.counterparty_name);

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.post(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response
}

export async function deleteCompanyBySlug(companyId) {
    let response = await axios.delete(url + '/companies/' + companyId + '/')
    if (response.status === 204) {
        return response.data
    } else {
        return null
    }

}

export async function getContractsByCompanyId(companyId) {
    let response = await axios.get(url + '/contracts/' + companyId + '/by_company/')
    return validateResponse(response)
}

export async function getContractsByCompanySlug(companySlug) {
    try {
        let response = await axios.get('/contract/' + companySlug + '/by_company/')
        return {
            ok: true,
            data: response.data
        }
    } catch {
        return {
            ok: false,
            data: null
        }
    }
}

export async function getDocumentsByContractSlug(contractSlug) {
    let response = {
        ok: true,
        data: null
    }
    try {
        let res = await axios.get(`/contract/document/${contractSlug}/`)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }
    return response
}

export async function getActsByContractSlug(contractSlug, limit, offset, query) {
    let response = {
        ok: true,
        data: null
    }
    let params = {
        limit: limit,
        offset: offset,
    }
    if (query && query.trim().length > 0) {
        params['search'] = query
    }
    try {
        let res = await axios.get('/contract/act/' + contractSlug + '/list/', {
            params: params
        })
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }
    return response
}

export async function updateAct(act) {
    const req_url = '/contract/act/' + act.slug + '/update/';
    const formData = new FormData();

    formData.append('name', act.name);
    formData.append('contract', act.contract);
    formData.append('category_id', act.category_id);
    formData.append('comment', act.comment);
    formData.append('file', act.file);
    formData.append('date', act.date ? act.date : new Date().toString());

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.put(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response
    }

    return response
}


export async function createAct(act) {
    const req_url = 'contract/act/create/';
    const formData = new FormData();

    formData.append('name', act.name);
    formData.append('category_id', act.category_id);
    formData.append('contract_slug', act.contract_slug);
    formData.append('comment', act.comment);
    formData.append('file', act.file);
    formData.append('date', act.date ? act.date : new Date().toString());

    let response = {
        ok: true,
        data: null
    }

    try {
        let res = await axios.post(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response
    }

    return response
}


export async function deleteActBySlug(actSlug) {
    let response = {
        ok: true,
        data: null
    }
    try {
        await axios.delete('/contract/act/' + actSlug + '/delete/')
    } catch (error) {
        response.ok = false
    }

    return response
}


// DOCUMENTS DOCUMENTS DOCUMENTS DOCUMENTS

export async function createDocument(doc) {
    const req_url = `/contract/document/${doc.contract_slug}/create/`;
    const formData = new FormData();

    formData.append('name', doc.name);
    formData.append('comment', doc.comment);
    formData.append('file', doc.file);

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.post(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response
}

export async function updateDocument(doc) {
    const req_url = `/contract/document/${doc.id}/update/`;
    const formData = new FormData();

    formData.append('name', doc.name);
    formData.append('comment', doc.comment);

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.put(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response
}

export async function deleteDocumentById(documentId) {
    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.delete(`/contract/document/${documentId}/delete/`)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response

}


// CONTRACTS CONTRACTS CONTRACTS CONTRACTS

export async function updateContract(contract) {
    const req_url = '/contract/' + contract.id + '/update/';
    const formData = new FormData();

    formData.append('name', contract.name);
    formData.append('company_slug', contract.company_slug);
    formData.append('start_date', contract.start_date);
    formData.append('expire_date', contract.expire_date);

    let response = {
        ok: true, data: null
    }
    try {
        let res = await axios.put(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response
}

export async function deleteContractById(contractId) {
    let response = {
        ok: true, data: null
    }
    try {
        await axios.delete('/contract/' + contractId + '/delete/')
    } catch (e) {
        response.ok = false
    }
    return response
}

export async function createContract(contract) {
    const req_url = '/contract/create/';
    const formData = new FormData();

    formData.append('name', contract.name);
    formData.append('company_slug', contract.company_slug);
    formData.append('start_date', contract.start_date);
    formData.append('expire_date', contract.expire_date);

    let response = {
        ok: true,
        data: null
    };

    try {
        let res = await axios.post(req_url, formData)
        response.data = res.data
    } catch (error) {
        response.ok = false
        response.data = error.response.data
    }

    return response
}